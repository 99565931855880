'use client';

import React from 'react';

export default function GlobalError({
  error,
  reset
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <h4>Diagnotic information</h4>
        <p>Name: {error.name}</p>
        <p>Message: {error.message}</p>
        <p>Stack: {error.stack}</p>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
